export const columns = [
  {
    prop: 'code',
    label: '生产任务号'
  },
  {
    prop: 'productionStatus',
    label: '生产状态'
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码'
  },
  {
    prop: 'procedureCode',
    label: '工序编码'
  },
  {
    prop: 'procedureName',
    label: '工序名称'
  },
  {
    prop: 'planOutputQuantity',
    label: '计划产量'
  },
  {
    prop: 'materialsCode',
    label: '物料编码'
  },
  {
    prop: 'materialsName',
    label: '物料名称'
  },
    {
    prop: 'specifications',
    label: '物料规格'
  },
  {
    prop: 'mainUnitName',
    label: '单位'
  },
  {
    prop: 'planStartTime',
    label: '计划开工时间'
  },
  {
    prop: 'planEndTime',
    label: '计划完工时间'
  },
  {
    prop: 'estimateEndTime',
    label: '预计完成时间'
  },
  {
    prop: 'delayReason',
    label: '延期原因'
  },
  {
    prop: 'feedbackTime',
    label: '最近反馈日期'
  },
  {
    prop: 'planUserGroupName',
    label: '计划执行小组'
  },
  {
    prop: 'planUserName',
    label: '计划执行人'
  },
  {
    prop: 'actualStartTime',
    label: '实际开工时间'
  },
  {
    prop: 'actualEndTime',
    label: '实际完工时间'
  },
  {
    prop: 'actualUserName',
    label: '实际执行人'
  },
  {
    prop: 'standardHours',
    label: '标准工时'
  },
  {
    prop: 'reportWorkHours',
    label: '已报工时'
  },
  {
    prop: 'taskProcessPercent',
    label: '计划进度'
  },
  {
    prop: 'actualProcessPercent',
    label: '实际进度'
  },
  {
    prop: 'beforeTask',
    label: '前置任务'
  },
  {
    prop: 'producePlanNumber',
    label: '所属生产工单'
  },
  {
    prop: 'sequenceNumber',
    label: '产成品序列号'
  },
  {
    prop: 'businessUnitName',
    label: '业务部门'
  },
  {
    prop: 'remark',
    label: '备注'
  }
]

// 报工记录
export const materialColumns = [
  {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'userName',
      label: '生产人员',
      sortable: false,
      hiddenSearch: true
    },
    // {
    //   prop: 'userGroupName',
    //   label: '用户组',
    //   sortable: false,
    //   hiddenSearch: true
    // },
      {
        prop: 'workHours',
        label: '工时/h',
        sortable: false,
        hiddenSearch: true
      },
    {
      prop: 'workHoursType',
      label: '工时类型',
      slotName: 'workHoursType',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'planTime',
      label: '生产时间',
      slotName: 'planTime',
      sortable: false,
      hiddenSearch: true,
      width: 320
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    }
]
//计划用料
export const materialPlanColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    width: 80
  },
  {
    prop: 'materialsCode',
    label: '物料编码',
    hiddenSearch: true,
    sortable: false,
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'specifications',
    label: '物料规格',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'materialsQuantity',
    label: '需求数量',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false
  }
]
  // 投料记录
export const inputMaterialsRecordColumns = [
    {
      prop: 'index',
      label: '序号',
      sortable: false,
      slotName:'index',
      minWidth: 60,
      hiddenSearch: true
    },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'isKeyMaterials',
      label: '关键料',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      slotName: 'isKeyMaterials'
    },
    {
      prop: 'settingVariable',
      label: '设变件',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      slotName: 'settingVariable'
    },
    {
      prop: 'quantity',
      label: '数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 60
    },
    {
      prop: 'sequenceNumber',
      label: '序列号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'sourceBatchNumber',
      label: '来源批次码',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'sourceSupplierName',
      label: '来源供应商',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'sourcePurchaseOrderCode',
      label: '来源采购单号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'mode',
      label: '投料方式',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120,
      slotName: 'mode'
    },
    {
      prop: 'creator',
      label: '投料人',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'createTime',
      label: '投料时间',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'status',
      label: '状态',
      sortable: false,
      hiddenSearch: true,
      slotName: 'inputMaterialsRecordStatus',
      minWidth: 60
    }
  ]
  // 图纸附件
  export const fileAndDrawings = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      width: 80,
      hiddenSearch: true
    },
    {
      prop: 'name',
      label: '名称',
      slotName: 'fileName',
      sortable: false,
      hiddenSearch: true
    }
  ]
// 自检记录
export const workColumns = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      sortable: false,
      width: 80
    },
    {
      prop: 'code',
      label: '任务编号',
      slotName: 'code',
      hiddenSearch: true,
      sortable: false,
      width: 160
    },
    {
      prop: 'status',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
      slotName: 'status',
      width: 120
    },
    {
      prop: 'inspectType',
      label: '类型',
      hiddenSearch: true,
      sortable: false,
      slotName: 'inspectType',
      width: 80
    },
    {
      prop: 'inspectionType',
      label: '业务类型',
      hiddenSearch: true,
      sortable: false,
      slotName: 'inspectionType',
      width: 80
    },
    {
      prop: 'inspectionSchemeName',
      label: '质检方案',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'checkItemCount',
      label: '检验项数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'checkItemFinishCount',
      label: '检验项已完成数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    {
      prop: 'checkItemUnFinishCount',
      label: '检验项未完成数量',
      hiddenSearch: true,
      sortable: false,
      width: 120
    },
    // {
    //     prop: 'producePlanNumber',
    //     label: '生产工单',
    //     hiddenSearch: true,
    //     sortable: false,
    //     width: 180
    //   },
    //   {
    //     prop: 'materialsFullName',
    //     label: '生产产品',
    //     hiddenSearch: true,
    //     sortable: false,
    //     width: 180
    //   },
    //   {
    //     prop: 'productionTaskCode',
    //     label: '生产任务',
    //     hiddenSearch: true,
    //     sortable: false,
    //     width: 180
    //   },
    // {
    //   prop: 'procedureName',
    //   label: '工序',
    //   hiddenSearch: true,
    //   sortable: false,
    //   width: 120
    // },
    {
        prop: 'startTime',
        label: '开始时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
      {
        prop: 'endTime',
        label: '结束时间',
        hiddenSearch: true,
        sortable: false,
        width: 180
      },
    {
      prop: 'createUser',
      label: '创建人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 180
    },
    {
      prop: 'executorName',
      label: '执行人',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'businessUnitName',
      label: '业务部门',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'result',
      label: '结论判定',
      hiddenSearch: true,
      sortable: false,
      slotName: 'result',
      width: 180
    }
]
// 延期记录
export const delayItemColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'estimateEndTime',
    label: '预计完成时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'delayReason',
    label: '延期原因',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'attachments',
    label: '延期图片',
    sortable: false,
    slotName: 'attachments',
    hiddenSearch: true
  },
  {
    prop: 'feedbackTime',
    label: '反馈日期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'feedbackUserName',
    label: '反馈人',
    sortable: false,
    hiddenSearch: true
  }
]
