<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>装箱清单详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form ref="ruleForm" label-position="right" :model="ruleForm" label-width="110px" class="demo-form-inline">
          <!-- 详情 -->
          <el-row type="flex" justify="start" style="flex-flow:row wrap">
            <el-col v-for="item in detailBaseFields" :key="item.key" :span="6">
              <el-form-item :label="item.name" v-if="item.name === '备注：' && type === 'add'">
                <el-input v-model="ruleForm.remark" placeholder="" @blur="handleInputConfirm" />
              </el-form-item>
              <el-form-item :label="item.name" v-else>
                <div>{{ getDetailVal(item.key) }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">CASE清单</div>
      <div class="card-bot">
        <el-button v-if="type !== 'view' && permission('Add')" type="primary" size="small" @click="add" style="margin-bottom:8px">添加</el-button>
        <MTable ref="mTable" :columns="applyColumn" :height="400" :data="materialsList" :highlight-current-row="true"
          @row-click="handleRowClick" :show-page="false">
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <template slot="action" slot-scope="{ row }">
            <el-button type="text" @click="printCaseNumber(row)" v-if="type === 'view'">打印箱码</el-button>
            <el-button type="text" @click="printCaseMaterialsNumber(row)" v-if="type === 'view'">打印清单</el-button>
            <el-button type="text" @click="del(0, row)" v-if="type !== 'view'">删除</el-button>
          </template>

        </MTable>
      </div>
    </div>

    <div class="detail-card">
      <div class="card-tit">装箱清单</div>
      <div class="card-bot">
        <el-button v-if="type !== 'view' && parentId && permission('BindMaterials')" type="primary" size="small" @click="addMaterials"
          style="margin-bottom:8px">绑定物料</el-button>
        <MTable ref="mTableDetail" :columns="productionTaskColumn" :height="400" :data="bindingMaterialsList"
          :show-page="false">
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="sequenceNumber" slot-scope="{row }">
            <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
              <span class="mr-10">
                {{ row.sequenceNumber || '-' }}
              </span>
            </el-tooltip>
          </div>
          <template slot="action" slot-scope="{ row }">
            <el-button type="text" @click="printBatchNumber(row)" v-if="type === 'view'"
              :disabled="!row.batchNumber">打印批次码</el-button>
            <el-button type="text" @click="del(1, row)" v-if="type !== 'view'">删除</el-button>
          </template>

        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">{{ type === 'addOrder' ? '取 消' : '返 回' }}</el-button>
        <!-- <el-button v-if="type!=='view'" type="primary" @click="save">确 定</el-button> -->
      </div>
    </div>
    <div class="lines" />
    <MDialog v-model="visible" title="添加" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
    <!-- 选择物料 -->
    <select-materials ref="selectPlanOrder" :visible.sync="showPlanOrder"
      :produce-order-id="this.ruleForm.productionPlanId" @submitForm="submitPlanOrder" />
    <MPrint v-model="printVisible" :chooseId="chooseId" :type="printType">
    </MPrint>
  </div>
</template>
<script>
import { detailBaseFields, productionTaskColumn, applyColumn } from './columns'
import SelectMaterials from '../components/selectMaterials'
import api from '@/api/production/packing-list'
import { formList } from '../form-list'
export default {
  name: 'PackingOrderDetail',
  components: { SelectMaterials },
  data() {
    return {
      api,
      visible: false,
      productionTaskColumn,
      ruleForm: {
        code: '',
        name: '',
        remark: ''
      },
      baseFormData: {},
      formData: {},
      detailBaseFields,
      materialsList: [],
      applyColumn,
      expandedRows: [],
      selectMaterials: [],
      searchId: undefined,
      showPlanOrder: false,
      orderId: '',
      bindingMaterialsList: [],
      formList,
      parentId: '',
      printVisible: false,
      chooseId: '',
      printType: ''
    }
  },

  async created() {
    this.type = this.$route.query.type
    await this.getMaterialsList()
    await this.getDetail()
  },
  mounted() { },
  methods: {
    async printBatchNumber(row) {
      this.printType = 'YYHL_PACKAGING_ORDER_MATERIALS_BATCH_NUMBER'
      this.printVisible = true
      this.chooseId = row.batchNumberId
    },
    async printCaseNumber(row) {
      this.printType = 'YYHL_PACKAGING_ORDER_CASE_BATCH_NUMBER'
      this.printVisible = true
      this.chooseId = row.batchNumberId
    },
    async printCaseMaterialsNumber(row) {
      this.printType = 'YYHL_PACKAGING_ORDER_CASE_MATERIALS_BATCH_NUMBER'
      this.printVisible = true
      this.chooseId = row.id
    },
    async handleInputConfirm() {
      const res = await api.updatePackingOrderRemark({
        id: this.$route.query.id,
        remark: this.ruleForm.remark
      })
      if (res) {
        this.$message.success('修改成功')
      }
    },
    add() {
      this.visible = true
    },
    async handleRowClick(row) {
      this.parentId = row.id
      this.getPackingOrderMaterialsList()
    },
    async getPackingOrderMaterialsList() {
      const res = await api.getPackingOrderDetailList({
        id: this.$route.query.id,
        type: 1,
        parentId: this.parentId
      })
      if (res) {
        this.bindingMaterialsList = res
      }
    },
    async submitPlanOrder(val) {
      this.showPlanOrder = false
      let arr = val.map(item => {
        return {
          packagingOrderId: this.$route.query.id,
          materialsId: item.materialsId,
          materialsCode: item.materialsCode,
          materialsName: item.materialsName,
          materialsSpecifications: item.materialsSpecifications,
          quantity: item.quantity,
          batchNumber: item.batchNumber,
          sequenceNumber: item.sequenceNumber,
          parentId: this.parentId,
          remark: item.remark
        }
      })
      const res = await api.addPackingOrderCaseMaterials(arr)
      if (res) {
        this.$message.success('绑定成功')
        this.getPackingOrderMaterialsList()
      }
    },
    async submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async (valid) => {
        if (valid) {
          const res = await api.addPackingOrderCase({
            packagingOrderId: this.$route.query.id,
            ...form
          })
          if (res) {
            this.$message.success('添加成功')
            this.visible = false
            this.getMaterialsList()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    addMaterials() {
      // this.selectMaterials = this.materialsList
      this.showPlanOrder = true
    },
    async getMaterialsList() {
      const res = await api.getPackingOrderDetailList(
        {
          id: this.$route.query.id,
          type: 0
        })
      if (res) {
        this.materialsList = res
        this.parentId = this.materialsList.length ? this.materialsList[0].id : ''
        if (this.parentId) {
          this.$refs.mTable.tableRefs().setCurrentRow(this.materialsList[0])
          this.getPackingOrderMaterialsList()
        }
      }
    },
    async del(type, row) {
      const res = await api.delPackingOrderCase({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        if (!type) {
          this.getMaterialsList()
        } else {
          this.getPackingOrderMaterialsList()
        }

      }
    },
    // 获取装箱单基本信息值
    getDetailVal(key) {
      const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key] : '-'
      if (key === 'status') {
        const current = result === 1 ? '进行中' : '已完成'
        return current
      }
      return result
    },
    // 获取详情
    async getDetail() {
      const res = await api.getPackingOrderDetail({ id: this.$route.query.id })
      if (res) {
        this.ruleForm = {
          ...res
        }
      }
    },
    returnList() {
      this.$router.go(-1)
      localStorage.removeItem('applyMaterials')
    }
  }
}
</script>
  
<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;

    .el-form-item__label {
      padding-right: 0;
    }
  }
}

::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }

  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
}

.orderAdd {
  width: 100%;
  height: 100%;

  .procedure-detail-header {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #41456b;
    line-height: 20px;
    margin-bottom: 12px;
    cursor: pointer;

    .left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    img {
      width: 8px;
      height: 12px;
      display: block;
      margin-right: 10px;
    }
  }

  .input-new-tag {
    width: 130px;
  }

  .button-new-tag {
    height: 32px;
  }

  .detail-card {
    margin-bottom: 20px;
  }

  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }

  ::v-deep .el-select {
    width: 100%;
  }

  .e-btn {
    margin-bottom: 10px;
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
  