// 清单列表
export const columns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'code',
    label: '装箱单号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'procedureName',
    label: '工序',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'productionPlanNumber',
    label: '工单号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'projectNumber',
    label: '订单号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'materialsCode',
    label: '成品编号',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'materialsName',
    label: '成品名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '成品规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'stationNumber',
    label: '站号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    width: 100
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },

  {
    prop: 'updater',
    label: '更新人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'lastUpdateTime',
    label: '更新时间',
    hiddenSearch: true,
    sortable: false,
    width: 160
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 200,
    fixed: 'right'
  }
]
