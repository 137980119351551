// 详情基本信息字段
export const detailBaseFields = [
    {
      key: 'code',
      name: '装箱单号：'
    },
    {
      key: 'materialsCode',
      name: '成品编号：'
    },
    {
      key: 'materialsName',
      name: '成品名称：'
    },
    {
      key: 'materialsSpecifications',
      name: '成品规格：'
    },
    {
      key: 'stationNumber',
      name: '站号：'
    },
    // {
    //   key: 'receiptName',
    //   name: '总数量：'
    // },
    {
      key: 'procedureName',
      name: '工序：'
    },
    {
      key: 'productionPlanNumber',
      name: '工单号：'
    },
    {
      key: 'projectNumber',
      name: '订单号：'
    },
    {
      key: 'status',
      name: '状态：'
    },
    {
      key: 'remark',
      name: '备注：'
    },
    {
      key: 'creator',
      name: '创建人：'
    },
    {
      key: 'createTime',
      name: '创建时间：'
    },
    {
      key: 'updater',
      name: '更新人：'
    },
    {
      key: 'lastUpdateTime',
      name: '更新时间：'
    }
  ]
  // CASE清单
  export const applyColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'materialsCode',
      label: '箱号',
      sortable: false,
      hiddenSearch: true,
      form: { tagName: 'el-input',props:{disabled:true},defaultValue:'xh' }
    },
    // {
    //   prop: 'materialsName',
    //   label: '物料名称',
    //   sortable: false,
    //   hiddenSearch: true,
    //   form: { tagName: 'el-input' }
    // },
    // {
    //   prop: 'materialsSpecifications',
    //   label: '物料规格',
    //   sortable: false,
    //   hiddenSearch: true,
    //   minWidth: 140,
    //   form: { tagName: 'el-input',required:false}
    // },
    {
      prop: 'quantity',
      label: '数量',
      hiddenSearch: true,
      sortable: false,
      width:120,
      form: { tagName: 'el-input',props:{disabled:true},defaultValue:1 }
    },
    {
      prop: 'batchNumber',
      label: '箱码',
      sortable: false,
      hiddenSearch: true,
      form: { tagName: 'el-input',required:true }
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
     form: { tagName: 'el-input',required:false }
    },
    {
      prop: 'action',
      label: '操作',
      hiddenSearch: true,
      sortable: false,
      width: 160,
      slotName: 'action',
      fixed: 'right'
    }
    ]
  // 绑定物料
  export const productionTaskColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'materialsCode',
      label: '物料编号',
      sortable: false,
      hiddenSearch: true,
      width: 140
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'materialsSpecifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'quantity',
      label: '数量',
      hiddenSearch: true,
      sortable: false,
      width:100
    },
    {
      prop: 'batchNumber',
      label: '批次码',
      sortable: false,
      hiddenSearch: true,
      width:140
    },
    {
      prop: 'sequenceNumber',
      label: '序列号',
      sortable: false,
      hiddenSearch: true,
      slotName:'sequenceNumber',
      minWidth:140
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
    },
    {
      prop: 'action',
      label: '操作',
      hiddenSearch: true,
      sortable: false,
      width: 120,
      slotName: 'action',
      fixed: 'right'
    }
  ]
  