<template>
    <MDialog v-model="modalShow" width="80%" title="绑定物料" class="total-model" @onOk="submitForm" @cancel="cancel()" @fullScreen="fullScreenChange">
      <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
        <el-radio-button label="NoSchedule">拆分清单</el-radio-button>
        <el-radio-button label="HaveSchedule">物料</el-radio-button>
      </el-radio-group>
      <!-- <div class="top" style="padding-top: 0">
        <SearchForms
          ref="searchForms"
          :form-option="formOptions"
          style="padding-top: 10px;"
          @resetData="resetData"
          @searchData="searchTable"
        />
      </div> -->
      <!-- <el-divider class="e-divider" /> -->
      <div style="margin-top: 10px;">
        <!-- <FunctionHeader
          v-model="functionHeader.searchVal"
          :radio="params.radio"
          search-title="请输入批次码/工单号/物料编码/名称/规格"
          style="padding:0 0 10px 0"
          @search="$refs.mTables.setTableData(true)"
          v-if="params.radio === 'HaveSchedule'"
        /> -->
        <MTable
          ref="mTables"
          class="mTable"
          :columns="columns"
          :set-data-method="getTableData"
          :height="height"
          @selection-change="selectionChange"
        >
          <div slot="index" slot-scope="{  $index }">{{ $index + 1 }}</div>
          <div slot="quantity" slot-scope="{ row }">
             <div v-if="params.radio === 'NoSchedule'">{{ row.quantity }}</div>
             <el-input-number v-else v-model="row.quantity" :min="1" controls-position="right" :step="1"  style="width: 90%"></el-input-number>
            </div>
            <div slot="sequenceNumber" slot-scope="{row }">
          <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.sequenceNumber" placement="top-start">
            <span class="mr-10">
              {{ row.sequenceNumber || '-' }}
           </span>
          </el-tooltip>
        </div>
        </MTable>
        <div style="height: 15px;" />
      </div>
    </MDialog>
  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { columns } from './columns'
  import sapi from '@/api/production/split-list'
  import wapi from '@/api/site-warehouse/warehouse'
  
  export default {
    name: 'SelectMaterials',
    components: {  FunctionHeader },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      selectIds: {
        type: Array,
        default: () => ([])
      },
      produceOrderId: {
        type: Number,
        default: undefined
      }
    },
    data() {
      return {
        modalShow: false,
        columns,
        keyword: '',
        searchDatas: [],
        selectionData: [],
        currentType: 1,
        params: {
          radio: 'NoSchedule'
        },
        functionHeader: {
          searchVal: '',
          searchData: {}
        },
        selectKeyword: '1',
        selectionData: [],
        flag: 'NoSchedule',
        isShow: true,
        fullScreen: false
      }
    },
    computed: {
      height() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 : this.$store.state.app.contentHeight - 289 - 160
      },
      columnsKey() {
        return this.$route.name + this.params.radio
      }
    },
    watch: {
      visible(val) {
        this.modalShow = val
        this.selectionData = []
        // if (val) {
        //   this.$nextTick(() => {
        //     // 数据回显
        //     this.selectionData = this._.cloneDeep(this.selectIds)
        //     this.selectionData.forEach(item => {
        //       this.$refs.mTables.tableRefs().toggleRowSelection(item, true)
        //     })
        //   })
        // }
      },
      modalShow(val) {
        if (!val) {
          this.searchFormData = {}
          this.searchFormatData = []
          this.functionHeader.searchVal = ''
          this.$emit('update:visible', false)
        }
      }
    },
    async mounted() {
      this.modalShow = this.visible
    },
    methods: {
      fullScreenChange(val) {
        this.fullScreen = val
      },
      handleRowClick(row) {
        this.$refs.mTables.tableRefs().toggleRowSelection(row)
      },
      selectionChange(data) {
        if (Array.isArray(data)) {
          this.selectionData = data
        } else {
          this.selectionData = []
          this.selectionData.push(data)
        }
      },
      radioChange(label) {
        this.flag = label
        this.keyword = ''
        this.selectKeyword = ''
        this.isShow = true
        this.$refs.mTables.getCheckedColumns()
        this.$refs.mTables.setTableData(true)
      },
      // 查询
      searchTable(data, val, keyword) {
        this.searchDatas = data
        this.keyword = keyword
        this.selectKeyword = val
        console.log(data)
        if (data.length > 0) {
          const datas = data.filter(item => item.fieldName === 'status')
          this.isShow = datas.length > 0 ? !datas[0].fieldValue : true
        }
        this.$refs.mTables.setTableData(true)
      },
      // 重置
      resetData() {
        this.searchDatas = []
        this.selectKeyword = ''
        this.keyword = ''
        this.$refs.mTables.setTableData(true)
      },
      // 列表
      async getTableData(condition, callback) {
        const { page } = condition
        let apis
        apis = this.flag === 'NoSchedule' ? sapi.getSplitOrderDetailListByProductionPlanId : wapi.getPage
          const res = await apis({
            page: page.currentPage,
            limit: page.pageSize,
            // searchKeyword: this.functionHeader.searchVal,
            productionPlanId:this.produceOrderId
          })
        if (res) {
          callback({
            total: res.total,
            content: res.records.map(item =>{
            return {
              ...item,
              id: item.batchNumber + item.workOrderCode  + item.materialsId + (item.sequenceNumber !== null ? item.sequenceNumber : '')
            }
          })
          })
        }
        callback()
      },
      submitForm(callback) {
        callback()
        console.log(this.selectionData)
        if (this.selectionData.length === 0) {
          this.$message.info('请至少选择一条物料')
          return
        }
        this.$emit('submitForm', this.selectionData)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep .mubai-modal-wrap .el-dialog__body {
    padding: 10px 0 10px 10px!important;
  }
  // .m-radio-group {
  //   width: auto !important;
  //   float: right;
  //   margin: 10px 0;
  //   .el-radio-button {
  //     min-width: 60px !important;
  //     height: 40px;
  //   }
  //   .el-radio-button__inner {
  //     padding: 4px 8px !important;
  //     min-width: 40px !important;
  //   }
  // }
  .materials-info {
    padding: 10px;
  }
  .materials-head{
    padding:0 0 20px 0;
  }
  .functionHeaders {
    padding-top: 0;
  }
  .total-model {
    ::v-deep .el-divider--horizontal {
      margin: 5px 0 10px 0;
    }
  }
  
  </style>
  