import { transformColumnsToForm } from '@/utils'


// 设变断点记录表单字段
export const breakPointFormList = [
  {
    colSpan: 4,
    key: 'bom',
    tagName: 'el-input',
    props: { placeholder: '请输入BOM版本' }
  },
  {
    colSpan: 8,
    key: 'firstBreakpointTime',
    tagName: 'el-date-picker',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '首次断点开始时间',
      endPlaceholder: '结束时间',
      align: 'center',
      type: 'daterange',
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    }
  },
  {
    colSpan: 4,
    key: 'stationNumber',
    tagName: 'el-input',
    props: { placeholder: '请输入站号' }
  },
  {
    colSpan: 4,
    key: 'workOrderNumber',
    tagName: 'el-input',
    props: { placeholder: '请输入工单号' }
  }
]
