var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("装箱清单详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  "label-width": "110px"
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-flow": "row wrap" },
                    attrs: { type: "flex", justify: "start" }
                  },
                  _vm._l(_vm.detailBaseFields, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, attrs: { span: 6 } },
                      [
                        item.name === "备注：" && _vm.type === "add"
                          ? _c(
                              "el-form-item",
                              { attrs: { label: item.name } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "" },
                                  on: { blur: _vm.handleInputConfirm },
                                  model: {
                                    value: _vm.ruleForm.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "remark", $$v)
                                    },
                                    expression: "ruleForm.remark"
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "el-form-item",
                              { attrs: { label: item.name } },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                ])
                              ]
                            )
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("CASE清单")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.type !== "view" && _vm.permission("Add")
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "8px" },
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.add }
                  },
                  [_vm._v("添加")]
                )
              : _vm._e(),
            _c("MTable", {
              ref: "mTable",
              attrs: {
                columns: _vm.applyColumn,
                height: 400,
                data: _vm.materialsList,
                "highlight-current-row": true,
                "show-page": false
              },
              on: { "row-click": _vm.handleRowClick },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.type === "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.printCaseNumber(row)
                                }
                              }
                            },
                            [_vm._v("打印箱码")]
                          )
                        : _vm._e(),
                      _vm.type === "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.printCaseMaterialsNumber(row)
                                }
                              }
                            },
                            [_vm._v("打印清单")]
                          )
                        : _vm._e(),
                      _vm.type !== "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.del(0, row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("装箱清单")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _vm.type !== "view" &&
            _vm.parentId &&
            _vm.permission("BindMaterials")
              ? _c(
                  "el-button",
                  {
                    staticStyle: { "margin-bottom": "8px" },
                    attrs: { type: "primary", size: "small" },
                    on: { click: _vm.addMaterials }
                  },
                  [_vm._v("绑定物料")]
                )
              : _vm._e(),
            _c("MTable", {
              ref: "mTableDetail",
              attrs: {
                columns: _vm.productionTaskColumn,
                height: 400,
                data: _vm.bindingMaterialsList,
                "show-page": false
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "sequenceNumber",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: {
                              effect: "dark",
                              "popper-class": "sequence-tooltip",
                              content: row.sequenceNumber,
                              placement: "top-start"
                            }
                          },
                          [
                            _c("span", { staticClass: "mr-10" }, [
                              _vm._v(
                                " " + _vm._s(row.sequenceNumber || "-") + " "
                              )
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return [
                      _vm.type === "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                disabled: !row.batchNumber
                              },
                              on: {
                                click: function($event) {
                                  return _vm.printBatchNumber(row)
                                }
                              }
                            },
                            [_vm._v("打印批次码")]
                          )
                        : _vm._e(),
                      _vm.type !== "view"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.del(1, row)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        : _vm._e()
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(_vm.type === "addOrder" ? "取 消" : "返 回"))
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c(
        "MDialog",
        {
          attrs: { title: "添加" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c("select-materials", {
        ref: "selectPlanOrder",
        attrs: {
          visible: _vm.showPlanOrder,
          "produce-order-id": this.ruleForm.productionPlanId
        },
        on: {
          "update:visible": function($event) {
            _vm.showPlanOrder = $event
          },
          submitForm: _vm.submitPlanOrder
        }
      }),
      _c("MPrint", {
        attrs: { chooseId: _vm.chooseId, type: _vm.printType },
        model: {
          value: _vm.printVisible,
          callback: function($$v) {
            _vm.printVisible = $$v
          },
          expression: "printVisible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }