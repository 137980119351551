var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "scrap-wrap" }, [
    _c(
      "div",
      { staticClass: "top" },
      [
        _c("SearchForm", {
          ref: "searchForm",
          attrs: {
            "form-list-extend": _vm.searchFormList,
            "form-data": _vm.searchFormData,
            searchType: 1
          },
          on: {
            "update:formData": function($event) {
              _vm.searchFormData = $event
            },
            "update:form-data": function($event) {
              _vm.searchFormData = $event
            },
            search: _vm.searchTable
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c("FunctionHeader", {
          ref: "functionHeader",
          attrs: {
            "search-title": "请输入装箱单号",
            tags: _vm.functionHeader.tags,
            "export-name": _vm.functionHeader.exportName,
            hiddenSearch: true,
            "export-params": _vm.functionHeader.exportParams
          },
          on: {
            search: function($event) {
              return _vm.$refs.mTable.setTableData(true)
            }
          },
          model: {
            value: _vm.functionHeader.searchVal,
            callback: function($$v) {
              _vm.$set(_vm.functionHeader, "searchVal", $$v)
            },
            expression: "functionHeader.searchVal"
          }
        }),
        _c("MTable", {
          ref: "mTable",
          attrs: {
            columns: _vm.columns,
            height: _vm.height,
            "set-data-method": _vm.getTableData
          },
          scopedSlots: _vm._u([
            {
              key: "index",
              fn: function(ref) {
                var $index = ref.$index
                return _c("div", {}, [_vm._v(_vm._s($index + 1))])
              }
            },
            {
              key: "stationNumber",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _c(
                      "el-tooltip",
                      {
                        attrs: {
                          effect: "dark",
                          "popper-class": "sequence-tooltip",
                          content: row.stationNumber,
                          placement: "top-start"
                        }
                      },
                      [
                        _c("span", { staticClass: "mr-10" }, [
                          _vm._v(" " + _vm._s(row.stationNumber) + " ")
                        ])
                      ]
                    )
                  ],
                  1
                )
              }
            },
            {
              key: "status",
              fn: function(ref) {
                var row = ref.row
                return _c("div", {}, [
                  _c("span", [
                    _vm._v(_vm._s(row.status === 1 ? "进行中" : "已完成"))
                  ])
                ])
              }
            },
            {
              key: "action",
              fn: function(ref) {
                var row = ref.row
                return _c(
                  "div",
                  {},
                  [
                    _vm.permission("Detail")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.getDetailPath(row, "view")
                              }
                            }
                          },
                          [_vm._v("详情")]
                        )
                      : _vm._e(),
                    _vm.permission("Detail")
                      ? _c("el-divider", { attrs: { direction: "vertical" } })
                      : _vm._e(),
                    _vm.permission("Modify")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.getDetailPath(row, "add")
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.permission("Modify")
                      ? _c("el-divider", { attrs: { direction: "vertical" } })
                      : _vm._e(),
                    _vm.permission("Complete")
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text", disabled: row.status === 2 },
                            on: {
                              click: function($event) {
                                return _vm.complete(row)
                              }
                            }
                          },
                          [_vm._v("完工")]
                        )
                      : _vm._e()
                  ],
                  1
                )
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }