
// 设变断点列表字段
export const breakPointColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'firstBreakpointTime',
    label: '首次断点时间',
    sortable: false,
    hiddenSearch: true,
    width: 160
  },
  {
    prop: 'materialsCode',
    label: '设变件编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '设变件名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'materialsSpecifications',
    label: '设变件规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'sourceBatchNumber',
    label: '来源批次码',
    sortable: false,
    hiddenSearch: true,
    width: 140
  },
  {
    prop: 'supplierName',
    label: '来源供应商',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'purchaseOrderCode',
    label: '来源采购单号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'procedureName',
    label: '工序',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'workOrderNumber',
    label: '工单号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'projectNumber',
    label: '订单号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'finishedMaterialsCode',
    label: '成品编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'finishedMaterialsName',
    label: '成品名称',
    sortable: false,
    hiddenSearch: true,
    width: 180
  },
  {
    prop: 'finishedMaterialsSpecifications',
    label: '成品规格',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'stationNumber',
    label: '站号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'bomInfoVersion',
    label: 'BOM版本',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'creator',
    label: '创建人',
    hiddenSearch: true,
    sortable: false,
    width: 120
  },
  {
    prop: 'remark',
    label: '备注',
    hiddenSearch: true,
    sortable: false,
    width: 120
  }
]


