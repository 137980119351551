  export const columns = [
    {
      type: 'selection',
      width: 50,
      fixed: 'left',
      reserveSelection: true
    },
    {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        hiddenSearch: true,
        fixed: 'left',
        width: 60
      },
      {
        prop: 'materialsCode',
        label: '物料编号',
        sortable: false,
        hiddenSearch: true,
        width: 140
      },
      {
        prop: 'materialsName',
        label: '物料名称',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'materialsSpecifications',
        label: '物料规格',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'quantity',
        label: '数量',
        hiddenSearch: true,
        sortable: false,
        slotName: 'quantity',
        width:140
      },
      {
        prop: 'batchNumber',
        label: '批次码',
        sortable: false,
        hiddenSearch: true,
        width:140
      },
      {
        prop: 'sequenceNumber',
        label: '序列号',
        sortable: false,
        hiddenSearch: true,
        slotName: 'sequenceNumber',
        width:140
      },
      {
        prop: 'remark',
        label: '备注',
        sortable: false,
        hiddenSearch: true
      }
  ]
  