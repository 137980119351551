<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>拆分清单详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          label-width="110px"
          class="demo-form-inline"
        >
          <!-- 详情 -->
          <el-row type="flex" justify="start" style="flex-flow:row wrap">
            <el-col v-for="item in detailBaseFields" :key="item.key" :span="6">
              <el-form-item :label="item.name">
                <div>{{ getDetailVal(item.key) }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="detail-card" style="padding:10px">
      <FunctionHeader
          ref="functionHeader"
          :hiddenSearch="true"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          :splitOrderId="id"
          :import-name="functionHeader.importName"
        >
        <div slot="all">
        <el-select v-model="materialStatus" clearable placeholder="状态" @change="selectStatus">
                    <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  </div>
        </FunctionHeader>
        <MTable
          ref="mTable"
          :columns="productionTaskColumn"
          :height="400"
          :data="materialsList"
          :columns-setting="false"
          :show-page="false"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="materialsType" slot-scope="{row }">
            {{ row.materialsType }}
          </div>
          <div slot="status" slot-scope="{row }">
            {{ row.status ? '已交接':'未交接' }}
          </div>
          <!-- <div slot="planOutputQuantity" slot-scope="{ row }">
                      <el-input-number v-model="row.applyQuantity" :min="0" controls-position="right" :step="1"  style="width: 90%"></el-input-number>
                  </div> -->

          <template slot="action" slot-scope="{ row }">
            <el-button type="text" @click="print(row)" :disabled="!row.batchNumber">打印批次码</el-button>
          </template>

        </MTable>
       
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">{{ this.type!=='view'?'取 消':'返 回' }}</el-button>
        <el-button v-if="this.type!=='view'" type="primary" @click="save">确 定</el-button>
      </div>
    </div>
    <div class="lines" />
    <MPrint v-model="visible" :chooseId="chooseId" type="YYHL_SPLIT_ORDER_MATERIALS_BATCH_NUMBER">
    </MPrint>
  </div>
</template>
<script>
import api from '@/api/production/split-list'
import { detailBaseFields, productionTaskColumn } from './columns'
import FunctionHeader from '@/components/FunctionHeader/index'
export default {
  name: 'SplitOrderDetail',
  components: { FunctionHeader },
  data() {
    return {
      api,
      id:'',
      visible:false,
      productionTaskColumn,
      ruleForm: {},
      baseFormData: {},
      detailBaseFields,
      materialsList:[],
      functionHeader: {
      searchVal: '',
      importName: 'splitOrderDetailImport',
      exportName: 'exportSplitOrderDetail',
      exportParams: {},
      tags: []
    },
    chooseId:'',
    materialStatus:'',
    statusList:[
      {
        id:0,
        name:'未交接'
      },
      {
        id:1,
        name:'已交接'
      }
    ]
    }
  },

  async created() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    await this.getDetail()
    await this.getMaterialsList()
  },
  mounted(){
  },
  methods: {
    selectStatus(val){
      this.materialStatus = val
      this.getMaterialsList()
    },
    async print(row){
      this.visible = true
      this.chooseId = row.batchNumberId
    },
  // 获取领料单基本信息值
    getDetailVal(key) {
      const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key]:'-'
      if (key === 'status') {
        const current = result === 1 ? '进行中' :'已完成'
        return current
      }
    return result
    },
    // 获取详情
    async getDetail() {
      const res = await api.getSplitOrderDetail({id:this.$route.query.id})
      if (res) {
        this.ruleForm = {
          ...res
        }
      }
    },
    // 获取物料列表
    async getMaterialsList() {
      const res = await api.getSplitOrderDetailList({id:this.$route.query.id,status:this.materialStatus})
      if (res) {
        this.materialsList = res
        this.functionHeader.exportParams = {
          ...this.functionHeader.exportParams,
          splitOrderId:this.$route.query.id,
          status:this.materialStatus
        }
      }
    },
    returnList() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;
    .el-form-item__label {
      padding-right: 0;
    }
  }
}
.orderAdd {
  width: 100%;
  height: 100%;

  .procedure-detail-header {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456b;
      line-height: 20px;
      margin-bottom: 12px;
      cursor: pointer;
      .left {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }

      img {
        width: 8px;
        height: 12px;
        display: block;
        margin-right: 10px;
      }
}
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
