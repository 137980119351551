// 详情基本信息字段
export const detailBaseFields = [
  {
    key: 'code',
    name: '拆分单号：'
  },
  {
    key: 'materialsCode',
    name: '成品编号：'
  },
  {
    key: 'materialsName',
    name: '成品名称：'
  },
  {
    key: 'materialsSpecifications',
    name: '成品规格：'
  },
  {
    key: 'stationNumber',
    name: '站号：'
  },
  // {
  //   key: 'receiptName',
  //   name: '总数量：'
  // },
  {
    key: 'procedureName',
    name: '工序：'
  },
  {
    key: 'productionPlanNumber',
    name: '工单号：'
  },
  {
    key: 'projectNumber',
    name: '订单号：'
  },
  {
    key: 'status',
    name: '状态：'
  },
  {
    key: 'remark',
    name: '备注：'
  },
  {
    key: 'creator',
    name: '创建人：'
  },
  {
    key: 'createTime',
    name: '创建时间：'
  },
  {
    key: 'updater',
    name: '更新人：'
  },
  {
    key: 'lastUpdateTime',
    name: '更新时间：'
  }
]
// 物料字段
export const productionTaskColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsSpecifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'quantity',
    label: '数量',
    hiddenSearch: true,
    sortable: false,
    width:100
  },
  {
    prop: 'batchNumber',
    label: '批次码',
    sortable: false,
    hiddenSearch: true,
    width:140
  },
  {
    prop: 'sequenceNumber',
    label: '序列号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
  },
    {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status',
    minWidth:120
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'action',
    fixed: 'right'
  }
]
