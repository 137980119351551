var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("拆分清单详情")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-form-inline",
                attrs: {
                  "label-position": "right",
                  model: _vm.ruleForm,
                  "label-width": "110px"
                }
              },
              [
                _c(
                  "el-row",
                  {
                    staticStyle: { "flex-flow": "row wrap" },
                    attrs: { type: "flex", justify: "start" }
                  },
                  _vm._l(_vm.detailBaseFields, function(item) {
                    return _c(
                      "el-col",
                      { key: item.key, attrs: { span: 6 } },
                      [
                        _c("el-form-item", { attrs: { label: item.name } }, [
                          _c("div", [
                            _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                          ])
                        ])
                      ],
                      1
                    )
                  }),
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              attrs: {
                hiddenSearch: true,
                tags: _vm.functionHeader.tags,
                "export-name": _vm.functionHeader.exportName,
                "export-params": _vm.functionHeader.exportParams,
                splitOrderId: _vm.id,
                "import-name": _vm.functionHeader.importName
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "all" }, slot: "all" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "状态" },
                      on: { change: _vm.selectStatus },
                      model: {
                        value: _vm.materialStatus,
                        callback: function($$v) {
                          _vm.materialStatus = $$v
                        },
                        expression: "materialStatus"
                      }
                    },
                    _vm._l(_vm.statusList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ]
          ),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.productionTaskColumn,
              height: 400,
              data: _vm.materialsList,
              "columns-setting": false,
              "show-page": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "materialsType",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(row.materialsType) + " ")
                  ])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(" " + _vm._s(row.status ? "已交接" : "未交接") + " ")
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", disabled: !row.batchNumber },
                        on: {
                          click: function($event) {
                            return _vm.print(row)
                          }
                        }
                      },
                      [_vm._v("打印批次码")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
            ]),
            this.type !== "view"
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.save } },
                  [_vm._v("确 定")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("MPrint", {
        attrs: {
          chooseId: _vm.chooseId,
          type: "YYHL_SPLIT_ORDER_MATERIALS_BATCH_NUMBER"
        },
        model: {
          value: _vm.visible,
          callback: function($$v) {
            _vm.visible = $$v
          },
          expression: "visible"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }