<template>
    <div class="scrap-wrap">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data.sync="searchFormData"
          :searchType='1'
          @search="searchTable" />
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入装箱单号"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :hiddenSearch="true"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="stationNumber" slot-scope="{row }">
          <el-tooltip effect="dark" popper-class="sequence-tooltip" :content="row.stationNumber" placement="top-start">
            <span class="mr-10">
              {{ row.stationNumber }}
           </span>
          </el-tooltip>
        </div>
          <div slot="status" slot-scope="{ row }" >
            <span>{{ row.status === 1 ? '进行中' :'已完成' }}</span>
          </div>
          <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('Detail')" type="text" @click="getDetailPath(row,'view')">详情</el-button>
            <el-divider v-if="permission('Detail')" direction="vertical" />
            <el-button v-if="permission('Modify')" type="text" @click="getDetailPath(row,'add')">编辑</el-button>
            <el-divider v-if="permission('Modify')" direction="vertical" />
            <el-button v-if="permission('Complete')" type="text" @click="complete(row)" :disabled="row.status === 2">完工</el-button>
            <!-- <el-divider v-if="permission('Complete')" direction="vertical" />
            <el-button v-if="permission('Print')" type="text" @click="issuePurchaseOrder()">打印</el-button> -->
          </div>
        </MTable>
      </div>
    </div>
  </template>

  <script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columns } from './columns'
  import api from '@/api/production/packing-list'
  import conventionMixin from '@/mixin/conventional-page'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { searchFormList } from './form-list'

  export default {
    name: 'PackingOrderList',
    components: { NewPageOpen, SearchForm, FunctionHeader },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        projectNumber:'',
        params: {
          radio: 'PackingOrder'
        },
        columns,
        searchFormData: {},
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          ],
          exportName: '',
          exportParams: {},
          importName: 'planOrderImport',
        },
        formData: {},
        currentType: 'add',
        visible: false,
        searchKeyword: true,
        isPageLoaded:false,
        searchFormList,
        projectId:''
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 25
      }
    },
    watch: {
    },
    created() {
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
      async complete(row){
        this.$confirm('是否确认完工？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          console.log(row.id)
          const res = await api.completePackingOrder(row.id)
          if(res){
            this.$message({
              type: 'success',
              message: '完工成功!'
            })
            this.$refs.mTable.setTableData()
          }
        })
      },
      async getTableData(condition, callback) {
        const { page } = condition
        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          searchKeyword: this.functionHeader.searchVal,
          ...data,
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          limit: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
    issuePurchaseOrder(){
      // this.$router.push({ name: 'reserveStockOrderList'})
    },
      getDetailPath(row,command) {
        this.$router.push({ name: 'PackingOrderDetail', query: { id: row.id, type: command }})
      },
      searchTable(data,val) {
        if (val === 1) {
        this.searchFormData = {}
      }
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }
  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    ::v-deep {
    .columns-setting {
      top: -28px;
    }
  }
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item__content {
      height:32px;
    }
        .form-builder-wrap .el-row {
          display: block;
        }
  }
  </style>
