import { applyColumn } from './detail/columns'
import { transformColumnsToForm } from '@/utils'
export const formList = transformColumnsToForm(applyColumn)

// 表单搜索字段
export const searchFormList = [
    {
        colSpan: 5,
        key: 'stationNumber',
        tagName: 'el-input',
        defaultValue: 1,
        attrs: {
          placeholder: '请输入站号'
        },
        children: []
      },
      {
        colSpan: 5,
        key: 'productionPlanNumber',
        tagName: 'el-input',
        defaultValue: 1,
        attrs: {
          placeholder: '请输入工单号'
        },
        children: []
      }
]
