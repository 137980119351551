<template>
  <div class="inventory-wrap common-tab">
    <div class="bottom">

      <div class="materials-info">
        <div v-show="breakPointFormList">
          <SearchForm
            ref="searchForm"
            :form-list-extend="breakPointFormList"
            :form-data="searchFormData"
            :searchType='1'
            @update:formData="data=>searchFormData=data"
            @search="searchTable"
          />
        </div>
        <div class="bottom">
          <FunctionHeader
            ref="functionHeader"
            v-model="functionHeader.searchVal"
            :search-title="'请输入设变件编码/名称/规格'"
            :radio="params.radio"
            :export-name="functionHeader.exportName"
            :export-params="functionHeader.exportParams"
            :columns="breakPointColumns"
            @search="$refs.mTable.setTableData(true)"
          >
          </FunctionHeader>
          <MTable
            ref="mTable"
            :columns="breakPointColumns"
            :height="height"
            :set-data-method="getTableData"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

          </MTable>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import { breakPointColumns } from './data'
  import { breakPointFormList } from './form-list'
  import api from '@/api/breakPoint/break-point'

  export default {
    name: 'BreakPoint',
    components: { FunctionHeader, SearchForm},
    data() {
      return {
        api,
        total:0,
        params: {
          radio: 'BreakPointItem'
        },
        functionHeader: {
          searchVal: '',
          exportName: '',
          exportParams: {}
        },
        breakPointColumns,
        breakPointFormList,
        searchFormData: {
        },
        searchFormatData: [],
        selectTableData: []
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 44
      }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
      async getTableData(condition, callback) {
        const { page } = condition
        const { firstBreakpointTime, ...data } = this.searchFormData
        const searchCondition = {
          ...data,
          materials: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getBreakPointListPage({
          page: page.currentPage,
          limit: page.pageSize,
          firstBreakpointStartTime: firstBreakpointTime ? firstBreakpointTime[0] : null,
          firstBreakpointEndTime: firstBreakpointTime ? firstBreakpointTime[1] : null,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
  }
</script>

<style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .inventory-wrap {
    width: 100%;
    padding-top: 10px;

    .m-radio-group {
      margin-left: 10px;
    }

    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
    }
  }
</style>
